/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.app {
  width: 100vw;
  overflow-x: hidden;
}
.app {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.nav1 {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  border: solid 1px lightgrey;
  overflow-x: hidden;
  background-color: #f7f7f7;
}
.nav1 div {
  margin-right: 2rem;
  text-align: center;
}
@media (max-width:400px) {
  .nav1 div {
    margin-right: 1rem;
  }
}
.nav1 div :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.nav1 div a {
  text-decoration: none;
  color: gray;
}
.nav1 div a :hover {
  color: white;
  transition: 0.6s;
}
.nav1 div a p.navLinks {
  font-family: 'Raleway';
  padding: 0.4rem;
  text-decoration: none;
}
@media (max-width:900px) {
  .nav1 div a p.navLinks {
    font-size: 0.9rem;
  }
}
@media (max-width:400px) {
  .nav1 div a p.navLinks {
    font-size: 0.5rem;
  }
}
.nav2 {
  display: flex;
  background-color: white;
  padding: 1rem;
  overflow-x: hidden;
  justify-content: space-between;
}
.nav2 .logo img {
  width: 18rem;
  margin-left: 2rem;
  margin-right: 0.5rem;
}
@media (max-width:700px) {
  .nav2 .logo img {
    width: 8rem;
    margin-left: 0;
  }
}
@media (max-width:400px) {
  .nav2 .logo img {
    width: 5rem;
  }
}
.nav2 .logo a {
  text-decoration: none;
  color: black;
}
.nav2 .logo a h1.navLinks {
  font-family: 'Raleway';
  padding: 0.4rem;
  text-decoration-line: initial;
}
@media (max-width:900px) {
  .nav2 .logo a h1.navLinks {
    font-size: 1.5rem;
  }
}
@media (max-width:400px) {
  .nav2 .logo a h1.navLinks {
    font-size: 1rem;
  }
}
.nav2 .nav2rightside {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.nav2 .nav2rightside .pestDropDown {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.nav2 .nav2rightside .pestDropDown a {
  text-decoration: none;
  color: black;
}
.nav2 .nav2rightside .pestDropDown a :visited {
  color: black;
}
.nav2 .nav2rightside .pestDropDown a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.nav2 .nav2rightside .pestDropDown a p.navLinks {
  font-family: 'Raleway';
  padding: 0.4rem;
  text-decoration-line: initial;
  font-size: 1.4rem;
}
@media (max-width:900px) {
  .nav2 .nav2rightside .pestDropDown a p.navLinks {
    font-size: 1rem;
  }
}
@media (max-width:400px) {
  .nav2 .nav2rightside .pestDropDown a p.navLinks {
    font-size: 0.5rem;
  }
}
.nav2 .nav2rightside .FreeQuote {
  display: flex;
  align-items: center;
}
.nav2 .nav2rightside .FreeQuote a {
  text-decoration: none;
  color: black;
}
.nav2 .nav2rightside .FreeQuote a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.nav2 .nav2rightside .FreeQuote a p.navLinks {
  font-family: 'Raleway';
  padding: 0.4rem;
  text-decoration-line: initial;
  font-size: 1.4rem;
}
@media (max-width:900px) {
  .nav2 .nav2rightside .FreeQuote a p.navLinks {
    font-size: 1rem;
  }
}
@media (max-width:400px) {
  .nav2 .nav2rightside .FreeQuote a p.navLinks {
    font-size: 0.5rem;
  }
}
.maincontent {
  background-image: url("../../public/images/closeup.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 11px 8px -10px rgba(0, 0, 0, 0.2), inset 0 -11px 8px -10px rgba(0, 0, 0, 0.2);
  background-position: center;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 40rem;
  overflow-x: hidden;
}
@media (max-width:800px) {
  .maincontent {
    background-size: 100%;
    height: 30rem;
  }
}
@media (max-width:700px) {
  .maincontent {
    height: 25rem;
  }
}
@media (max-width:600px) {
  .maincontent {
    height: 20rem;
  }
}
@media (max-width:500px) {
  .maincontent {
    height: 18rem;
  }
}
@media (max-width:400px) {
  .maincontent {
    height: 15rem;
  }
}
.maincontent .why {
  display: none;
  justify-content: center;
  width: 50%;
  align-items: center;
}
.maincontent .why p {
  width: 50%;
  font-size: 2.5rem;
  padding: 2rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: 'Raleway';
  text-align: center;
}
@media (max-width:900px) {
  .maincontent .why p {
    font-size: 1.5rem;
  }
}
@media (max-width:700px) {
  .maincontent .why p {
    font-size: 1rem;
  }
}
@media (max-width:400px) {
  .maincontent .why p {
    font-size: 0.7rem;
  }
}
.maincontent .maincontentFiller {
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
  margin-left: 2rem;
  margin-top: 0rem;
  margin-bottom: 8rem;
}
@media (max-width:700px) {
  .maincontent .maincontentFiller {
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}
@media (max-width:400px) {
  .maincontent .maincontentFiller {
    margin-left: 0rem;
  }
}
.maincontent .maincontentFiller p {
  width: 70%;
  padding: 1rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: 'Raleway';
  text-align: center;
  font-size: 2rem;
}
@media (max-width: 1000px) {
  .maincontent .maincontentFiller p {
    font-size: 1.4rem;
  }
}
@media (max-width:700px) {
  .maincontent .maincontentFiller p {
    font-size: 1rem;
  }
}
@media (max-width:500px) {
  .maincontent .maincontentFiller p {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
}
@media (max-width:400px) {
  .maincontent .maincontentFiller p {
    font-size: 0.4rem;
  }
}
.bugs {
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
}
.bugs .bugsbar {
  text-align: center;
  border: solid 1px lightgrey;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.bugs .bugsbar p {
  font-family: "Raleway";
  font-size: 3rem;
}
@media (max-width:900px) {
  .bugs .bugsbar p {
    font-size: 2rem;
  }
}
@media (max-width:400px) {
  .bugs .bugsbar p {
    font-size: 1rem;
  }
}
.bugs .bugChart {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
}
@media (max-width:900px) {
  .bugs .bugChart {
    flex-direction: column;
  }
}
.bugs .bugChart .explainbugs {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media (max-width:900px) {
  .bugs .bugChart .explainbugs {
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }
}
@media (max-width:900px) {
  .bugs .bugChart .explainbugs img.arrow {
    display: none;
  }
}
.bugs .bugChart .explainbugs img.downarrow {
  display: none;
}
@media (max-width:900px) {
  .bugs .bugChart .explainbugs img.downarrow {
    display: flex;
  }
}
.bugs .bugChart .explainbugs p {
  font-family: 'Raleway';
  font-size: 2rem;
  text-align: center;
  line-height: 2rem;
  width: 70%;
  margin-right: 3rem;
}
@media (max-width:900px) {
  .bugs .bugChart .explainbugs p {
    margin-right: 0rem;
    font-size: 1.4rem;
  }
}
.bugs .bugChart .bugrow {
  padding: 2rem;
}
@media (max-width:900px) {
  .bugs .bugChart .bugrow {
    padding: 0rem;
  }
}
.bugs .bugChart .bugrow :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.bugs .bugChart .bugrow a {
  text-decoration: none;
  color: black;
}
.bugs .bugChart .bugrow a p {
  padding: 2rem;
  font-size: 1.5rem;
  font-family: 'Raleway';
  text-align: center;
}
@media (max-width:400px) {
  .bugs .bugChart .bugrow a p {
    font-size: 1rem;
  }
}
@media (max-width:900px) {
  .bugs .bugChart .bugrow a p {
    padding: 2rem;
  }
}
.bugs .bugChart .bugrow2 {
  vertical-align: center;
  padding-top: 5rem;
}
@media (max-width:900px) {
  .bugs .bugChart .bugrow2 {
    padding-top: 0rem;
  }
}
.house {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.house .treatbar {
  text-align: center;
  border: solid 1px lightgrey;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.house .treatbar p {
  font-family: "Raleway";
  font-size: 3rem;
}
@media (max-width:900px) {
  .house .treatbar p {
    font-size: 2rem;
  }
}
@media (max-width:400px) {
  .house .treatbar p {
    font-size: 1rem;
  }
}
.house .treatbar img {
  text-align: center;
  border: solid red;
}
.house .treatmentBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: #f7f7f7;
}
.house .treatmentBar .treatmentBarCols {
  flex-direction: column;
}
.house .treatmentBar .treatmentBarCols .images {
  text-align: center;
  margin-top: 1rem;
}
.house .treatmentBar .treatmentBarCols .text {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.house .treatmentBar .treatmentBarCols .text p {
  font-size: 2rem;
  font-family: "Raleway";
  text-align: center;
  padding: 2rem;
  width: 70%;
}
.house h3 {
  background-color: #f7f7f7;
  font-family: 'Raleway';
  color: #00b33c;
  font-size: 2rem;
  text-align: center;
  padding-bottom: 2rem;
}
@media (max-width:400px) {
  .house h3 {
    font-size: 1.3rem;
  }
}
.sectionfooter {
  display: flex;
  flex-direction: column;
  font-family: 'Raleway';
  overflow-x: hidden;
  border: solid 1px lightgrey;
}
.sectionfooter div.footerSection1 {
  display: flex;
  flex-direction: row;
}
@media (max-width:700px) {
  .sectionfooter div.footerSection1 {
    flex-direction: column;
    text-align: center;
    align-self: center;
  }
}
.sectionfooter div.footerSection1 .col1 {
  width: 33%;
  text-align: center;
  padding: 2rem;
}
@media (max-width:700px) {
  .sectionfooter div.footerSection1 .col1 {
    text-align: center;
    align-self: center;
  }
}
.sectionfooter div.footerSection1 .col1 img {
  width: 12rem;
}
@media (max-width:700px) {
  .sectionfooter div.footerSection1 .col1 img {
    width: 8rem;
    margin-left: 0;
  }
}
@media (max-width:400px) {
  .sectionfooter div.footerSection1 .col1 img {
    width: 5rem;
  }
}
.sectionfooter div.footerSection1 .col1 a {
  text-decoration: none;
  color: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.sectionfooter div.footerSection1 .col1 a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.sectionfooter div.footerSection1 .col1 a h2 {
  font-size: 2rem;
  text-decoration: none;
  color: black;
}
.sectionfooter div.footerSection1 .col1 a {
  text-decoration: none;
  color: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.sectionfooter div.footerSection1 .col1 a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.sectionfooter div.footerSection1 .col1 a p {
  margin-top: 1rem;
}
.sectionfooter div.footerSection1 .col2 {
  width: 33%;
  text-align: center;
  padding: 2rem;
  margin-top: 2rem;
}
@media (max-width:700px) {
  .sectionfooter div.footerSection1 .col2 {
    text-align: center;
    align-self: center;
    width: 80%;
  }
}
.sectionfooter div.footerSection1 .col2 a {
  text-decoration: none;
}
.sectionfooter div.footerSection1 .col2 a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.sectionfooter div.footerSection1 .col2 a h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: black;
  text-decoration: none;
}
.sectionfooter div.footerSection1 .col2 p {
  line-height: 1.5rem;
  text-align: center;
}
.sectionfooter div.footerSection1 .col3 {
  width: 33%;
  text-align: center;
  padding: 2rem;
}
@media (max-width:700px) {
  .sectionfooter div.footerSection1 .col3 {
    text-align: center;
    align-self: center;
  }
}
.sectionfooter div.footerSection1 .col3 h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}
.sectionfooter div.footerSection1 .col3 p.bold {
  line-height: 1.5rem;
  font-size: 1.2rem;
  text-decoration: underline;
  text-align: center;
}
.sectionfooter div.footerSection1 .col3 p {
  line-height: 2rem;
  text-align: center;
}
.sectionfooter div.footerSection2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: gray;
  padding: 3rem;
  font-family: 'Raleway';
  text-align: center;
}
.sectionfooter div.footerSection2 p {
  font-family: 'Raleway';
  color: white;
  margin-bottom: 2rem;
  text-align: center;
}
.sectionfooter div.footerSection2 a {
  text-decoration: none;
  color: white;
  text-align: center;
}
.sectionfooter .footerContact {
  display: flex;
}
.sectionfooter .footerContact a {
  text-decoration: none;
  color: black;
  margin: 6px !important;
  display: flex;
  justify-content: center;
  width: 50%;
}
.sectionfooter .footerContact a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.sectionfooter .footerContact a p {
  font-weight: bold !important;
  width: 70%;
  font-size: 20px !important;
  padding: 1rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: 'Raleway';
  text-align: center;
}
.location {
  background-image: url("../../public/images/map.jpg");
  background-size: cover;
  box-shadow: inset 0 11px 8px -10px rgba(0, 0, 0, 0.2), inset 0 -11px 8px -10px rgba(0, 0, 0, 0.2);
  background-position: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40rem;
  overflow-x: hidden;
}
@media (max-width:400px) {
  .location {
    flex-direction: column;
  }
}
.location .where1 {
  display: flex;
  flex-direction: column;
  width: 49%;
  font-size: 2.5rem;
  font-family: "Raleway";
  text-align: center;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  margin-left: 2rem;
}
@media (max-width:900px) {
  .location .where1 {
    font-size: 1.5rem;
    width: 100%;
  }
}
@media (max-width:400px) {
  .location .where1 {
    margin-left: 0rem;
    margin-top: 2rem;
  }
}
.location .where1 p {
  padding: 2rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: "Raleway";
  text-align: center;
}
@media (max-width:600px) {
  .location .where1 p {
    font-size: 1rem;
    width: 50%;
  }
}
.location .where1 div.bugQuote {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (max-width:400px) {
  .location .where1 div.bugQuote {
    display: none;
  }
}
.location .where1 div.bugQuote a {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
}
.location .where1 div.bugQuote a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.location .where1 div.bugQuote a p {
  font-family: "Raleway";
  font-size: 2rem;
  padding: 2rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  width: 50%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (max-width:600px) {
  .location .where1 div.bugQuote a p {
    font-size: 1rem;
  }
}
.location .where {
  width: 49%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media (max-width:400px) {
  .location .where {
    margin-top: 2rem;
    width: 100%;
  }
}
.location .where p {
  font-size: 2.5rem;
  margin-top: 3rem;
  padding: 2rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: "Raleway";
  text-align: center;
}
@media (max-width:400px) {
  .location .where p {
    padding: 1rem;
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
}
@media (max-width:600px) {
  .location .where p {
    font-size: 1rem;
  }
}
.location #bugQuote2 {
  display: none;
}
@media (max-width:400px) {
  .location #bugQuote2 {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
  .location #bugQuote2 a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
  }
  .location #bugQuote2 a :hover {
    background-color: #00b33c;
    color: white;
    transition: 0.6s;
    cursor: pointer;
  }
  .location #bugQuote2 a p {
    font-family: "Raleway";
    font-size: 2rem;
    padding: 2rem;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 0.8);
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media (max-width:400px) and (max-width:600px) {
  .location #bugQuote2 a p {
    font-size: 1rem;
  }
}
.maincontent2 {
  background-image: url("../../public/images/hero.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 11px 8px -10px rgba(0, 0, 0, 0.2), inset 0 -11px 8px -10px rgba(0, 0, 0, 0.2);
  background-position: center;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 40rem;
  overflow-x: hidden;
}
@media (max-width:800px) {
  .maincontent2 {
    background-size: 100%;
    height: 30rem;
  }
}
@media (max-width:700px) {
  .maincontent2 {
    height: 25rem;
  }
}
@media (max-width:600px) {
  .maincontent2 {
    height: 20rem;
  }
}
@media (max-width:500px) {
  .maincontent2 {
    height: 18rem;
  }
}
@media (max-width:400px) {
  .maincontent2 {
    height: 15rem;
  }
}
.maincontent2 .why2 {
  display: none;
  justify-content: center;
  width: 50%;
  align-items: center;
}
.maincontent2 .why2 p {
  width: 50%;
  font-size: 2.5rem;
  padding: 2rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: 'Raleway';
  text-align: center;
}
@media (max-width:900px) {
  .maincontent2 .why2 p {
    font-size: 1.5rem;
  }
}
@media (max-width:700px) {
  .maincontent2 .why2 p {
    font-size: 1rem;
  }
}
@media (max-width:400px) {
  .maincontent2 .why2 p {
    font-size: 0.7rem;
  }
}
.maincontent2 .maincontentFiller2 {
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
  margin-left: 2rem;
  margin-top: 0rem;
  margin-bottom: 8rem;
}
@media (max-width:700px) {
  .maincontent2 .maincontentFiller2 {
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}
@media (max-width:400px) {
  .maincontent2 .maincontentFiller2 {
    margin-left: 0rem;
  }
}
.maincontent2 .maincontentFiller2 div.contactSection2 a {
  text-decoration: none;
  color: black;
  margin: 1rem;
}
.maincontent2 .maincontentFiller2 div.contactSection2 a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.maincontent2 .maincontentFiller2 div.contactSection2 a p {
  width: 70%;
  padding: 1rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: 'Raleway';
  text-align: center;
  font-size: 2rem;
}
@media (max-width: 1000px) {
  .maincontent2 .maincontentFiller2 div.contactSection2 a p {
    font-size: 1.4rem;
  }
}
@media (max-width:700px) {
  .maincontent2 .maincontentFiller2 div.contactSection2 a p {
    font-size: 1rem;
  }
}
@media (max-width:500px) {
  .maincontent2 .maincontentFiller2 div.contactSection2 a p {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
}
@media (max-width:400px) {
  .maincontent2 .maincontentFiller2 div.contactSection2 a p {
    font-size: 0.4rem;
  }
}
.about {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.about .aboutbar {
  text-align: center;
  border: solid 1px lightgrey;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.about .aboutbar p {
  font-family: "Raleway";
  font-size: 3rem;
}
@media (max-width:700px) {
  .about .aboutbar p {
    font-size: 2.4rem;
  }
}
@media (max-width:400px) {
  .about .aboutbar p {
    font-size: 1.5rem;
  }
}
.about .aboutChart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f7f7f7;
  padding-left: 10rem;
  padding-right: 10rem;
}
@media (max-width:700px) {
  .about .aboutChart {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media (max-width:400px) {
  .about .aboutChart {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.about .aboutChart .chartContainer h2 {
  font-family: 'Raleway';
  color: black;
  font-size: 2.6rem;
  padding-top: 2rem;
}
@media (max-width:700px) {
  .about .aboutChart .chartContainer h2 {
    font-size: 2rem;
  }
}
@media (max-width:400px) {
  .about .aboutChart .chartContainer h2 {
    font-size: 1.3rem;
  }
}
.about .aboutChart .chartContainer h3 {
  font-family: 'Raleway';
  padding-top: 3rem;
  color: #00b33c;
  font-size: 2rem;
}
@media (max-width:400px) {
  .about .aboutChart .chartContainer h3 {
    font-size: 1.3rem;
  }
}
.about .aboutChart .chartContainer p {
  font-family: 'Raleway';
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media (max-width:400px) {
  .about .aboutChart .chartContainer p {
    font-size: 0.9rem;
  }
}
.about .aboutChart .chartContainer p#already {
  margin-bottom: 2rem;
}
.bugdescribe {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}
@media (max-width: 1000px) {
  .bugdescribe {
    flex-direction: column;
  }
}
.bugdescribe div.imgContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
}
@media (max-width: 1000px) {
  .bugdescribe div.imgContainer {
    width: 100%;
  }
}
.bugdescribe div.imgContainer img {
  width: 40rem;
  height: 25rem;
}
@media (max-width: 1300px) {
  .bugdescribe div.imgContainer img {
    width: 30rem;
    height: 15rem;
  }
}
@media (max-width: 1000px) {
  .bugdescribe div.imgContainer img {
    width: 20rem;
    height: 10rem;
    margin-top: 1rem;
  }
}
@media (max-width:400px) {
  .bugdescribe div.imgContainer img {
    width: 10rem;
    height: 5rem;
  }
}
.bugdescribe .section {
  display: flex;
  flex-direction: column;
  width: 50%;
}
@media (max-width: 1000px) {
  .bugdescribe .section {
    width: 100%;
  }
}
.bugdescribe .section div.bugDescription h2 {
  font-family: 'Raleway';
  font-size: 2rem;
  text-align: center;
  margin-top: 1rem;
}
@media (max-width:400px) {
  .bugdescribe .section div.bugDescription h2 {
    font-size: 1.3rem;
  }
}
.bugdescribe .section div.bugDescription p {
  font-family: 'Raleway';
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.7rem;
  padding-right: 4rem;
  padding-left: 4rem;
}
@media (max-width:400px) {
  .bugdescribe .section div.bugDescription p {
    font-size: 0.7rem;
  }
}
.bugdescribe .section div.bugQuote {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.bugdescribe .section div.bugQuote a {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
}
.bugdescribe .section div.bugQuote a :hover {
  background-color: #00b33c;
  color: white;
  transition: 0.6s;
  cursor: pointer;
}
.bugdescribe .section div.bugQuote a p {
  font-family: 'Raleway';
  font-size: 2rem;
  background: lightgray;
  border-radius: 5px;
  width: 50%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (max-width:400px) {
  .bugdescribe .section div.bugQuote a p {
    font-size: 1rem;
  }
}
.how {
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
}
.how .howbar {
  text-align: center;
  border: solid 1px lightgrey;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.how .howbar p {
  font-family: "Raleway";
  font-size: 3rem;
}
@media (max-width:900px) {
  .how .howbar p {
    font-size: 2rem;
  }
}
@media (max-width:400px) {
  .how .howbar p {
    font-size: 1rem;
  }
}
.how .howChart {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
}
@media (max-width:900px) {
  .how .howChart {
    flex-direction: column;
  }
}
.how .howChart .explainHow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media (max-width:900px) {
  .how .howChart .explainHow {
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }
}
@media (max-width:900px) {
  .how .howChart .explainHow img.arrow {
    display: none;
  }
}
.how .howChart .explainHow img.downarrow {
  display: none;
}
@media (max-width:900px) {
  .how .howChart .explainHow img.downarrow {
    display: flex;
  }
}
.how .howChart .explainHow p {
  font-family: 'Raleway';
  font-size: 1.8rem;
  text-align: center;
  line-height: 2rem;
  width: 70%;
  padding: 4rem;
  margin-right: 3rem;
}
@media (max-width:900px) {
  .how .howChart .explainHow p {
    margin-right: 0rem;
    font-size: 1.4rem;
  }
}
.maincontent1 {
  background-image: url("../../public/images/hero.JPG");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 11px 8px -10px rgba(0, 0, 0, 0.2), inset 0 -11px 8px -10px rgba(0, 0, 0, 0.2);
  background-position: center;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 40rem;
  overflow-x: hidden;
}
@media (max-width:800px) {
  .maincontent1 {
    background-size: 100%;
    height: 30rem;
  }
}
@media (max-width:700px) {
  .maincontent1 {
    height: 25rem;
  }
}
@media (max-width:600px) {
  .maincontent1 {
    height: 20rem;
  }
}
@media (max-width:500px) {
  .maincontent1 {
    height: 18rem;
  }
}
@media (max-width:400px) {
  .maincontent1 {
    height: 15rem;
  }
}
.maincontent1 .why1 {
  display: none;
  justify-content: center;
  width: 50%;
  align-items: center;
}
.maincontent1 .why1 p {
  width: 50%;
  font-size: 2.5rem;
  padding: 2rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: 'Raleway';
  text-align: center;
  margin: 1rem;
}
@media (max-width:900px) {
  .maincontent1 .why1 p {
    font-size: 1.5rem;
  }
}
@media (max-width:700px) {
  .maincontent1 .why1 p {
    font-size: 1rem;
  }
}
@media (max-width:400px) {
  .maincontent1 .why1 p {
    font-size: 0.7rem;
  }
}
.maincontent1 .maincontentFiller1 {
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
  margin-left: 2rem;
  margin-top: 0rem;
  margin-bottom: 8rem;
}
@media (max-width:700px) {
  .maincontent1 .maincontentFiller1 {
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}
@media (max-width:400px) {
  .maincontent1 .maincontentFiller1 {
    margin-left: 0rem;
  }
}
.maincontent1 .maincontentFiller1 p {
  width: 70%;
  padding: 1rem;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.8);
  font-family: 'Raleway';
  text-align: center;
  font-size: 2rem;
}
@media (max-width: 1000px) {
  .maincontent1 .maincontentFiller1 p {
    font-size: 1.4rem;
  }
}
@media (max-width:700px) {
  .maincontent1 .maincontentFiller1 p {
    font-size: 1rem;
  }
}
@media (max-width:500px) {
  .maincontent1 .maincontentFiller1 p {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
}
@media (max-width:400px) {
  .maincontent1 .maincontentFiller1 p {
    font-size: 0.4rem;
  }
}
